import React from 'react';

import centroid from '@turf/centroid';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LaunchIcon from '@mui/icons-material/Launch';

import { EDITOR_URL } from '@src/config';
import useCallApi from '@src/hooks/useCallApi';
import Page from '@src/components/Page';


function EditorLink({ zone }) {
  const mapCenter = centroid(zone);
  const [lon, lat] = mapCenter.geometry.coordinates;
  const name = zone.properties?.title;
  return (
    <ListItem>
      <ListItemButton component="a" href={`${EDITOR_URL}#map=18/${lat}/${lon}`}>
        <ListItemIcon>
          <LaunchIcon/>
        </ListItemIcon>
        <ListItemText>
          {name}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}

export default function WorkingZones() {
  const workingZones = useCallApi('/working-zones/');
  const features = workingZones?.features ?? [];
  return (
    <Page
      title={<Typography variant="h1">Zones de travail</Typography>}
    >
      <List>
        {features.map((feature) => (
          <EditorLink key={feature.properties.id} zone={feature}/>
        ))}
      </List>
    </Page>
  );
}
