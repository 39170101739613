import React from 'react';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Page from '@src/components/Page';
import MAMP_logo from '@assets/img/mamp-logo.png';
import ECLA_logo from '@assets/img/ecla-logo.png';
import Evian_logo from '@assets/img/evian-logo.png';
import Someware_logo from '@assets/img/someware-logo.png';
import JungleBus_logo from '@assets/img/junglebus-logo.png';
import AdrienPavie_logo from '@assets/img/adrienpavie-logo.png';
import Atipy_logo from '@assets/img/atipy-logo.png';
import * as styles from './Home.module.css';


function ItemLogo({ url, img, alt, ...gridProps }) {
  return (
    <Grid item sx={{ display: 'flex' }} {...gridProps }>
      <a href={url} target="_blank" style={{ margin: 'auto' }}>
        <img src={img} alt="alt" style={{ maxWidth: '180px' }}/>
      </a>
    </Grid>
  );
}


export default function Home() {
  return (
    <Page
      title={<Typography variant="h1">Accueil</Typography>}
    >
      <div className={styles.textContent}>
        <Typography variant="h2">
          De futurs outils pour cartographie l'accessibilité Voirie / Transports / ERP.
        </Typography>
        <Typography>
          Des outils open source de relevé de terrain des données d’accessibilité
          dans les transports et sur la voirie alentour, respectant le standard CNIG - Accessibilité
          et le format d'échange NeTEx.
        </Typography>
        <Typography>
          <b>Calendrier</b>
        </Typography>
        <Typography component="div">
          <ul>
            <li>octobre 2022 : lancement du projet</li>
            <li>de juin à décembre 2023 : tests avec les collectivités pilotes</li>
            <li>début 2024 : lancement de la V1</li>
          </ul>
        </Typography>
        <Typography>
          <b>Territoires pilotes</b>
        </Typography>
        <Grid container spacing={2}>
          <ItemLogo sm={4} xs={12} url="https://ampmetropole.fr" img={MAMP_logo} alt="Métropole Aix Marseille Provence"/>
          <ItemLogo sm={4} xs={12} url="https://www.ecla-jura.fr" img={ECLA_logo} alt="ECLA Lons Agglo"/>
          <ItemLogo sm={4} xs={12} url="https://ville-evian.fr" img={Evian_logo} alt="Ville Evian"/>
        </Grid>
        <Typography>
          <b>Réalisation</b>
        </Typography>
        <Grid container spacing={2}>
          <ItemLogo md={3} sm={6} xs={12} url="https://www.someware.fr" img={Someware_logo} alt="Someware Handimap La vilel accessible à tous"/>
          <ItemLogo md={3} sm={6} xs={12} url="https://junglebus.io" img={JungleBus_logo} alt="Jungle Bus"/>
          <ItemLogo md={3} sm={6} xs={12} url="https://pavie.info" img={AdrienPavie_logo} alt="Adrien Pavie"/>
          <ItemLogo md={3} sm={6} xs={12} url="https://www.atipy.fr" img={Atipy_logo} alt="Atipy conception universelle et +"/>
        </Grid>
      </div>
    </Page>
  );
}
