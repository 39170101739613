import React from 'react';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { OIDC_CONFIG } from '@src/config';
import Root from './Root';
import Home from './pages/Home';
import WorkingZones from './pages/WorkingZones';
import Edit from './pages/Edit';
import Account from './pages/Account';
import Configure from './pages/Configure';
import ConfigWizard from './pages/ConfigWizard';
import ConfigHome from './pages/ConfigHome';

import MobileApp from './pages/MobileApp';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "configure",
        element: <Configure />,
        children: [
          {
            index: true,
            element: <ConfigHome />,
          },
          {
            path: "wizard",
            element: <ConfigWizard />,
          }
        ],
      },
      {
        path: "working-zones",
        element: <WorkingZones />,
      },
      {
        path: "edit",
        element: <Edit />,
      },
      {
        path: "app",
        element: <MobileApp />,
      }
    ],
  },
]);

const onSignIn = () => {
  redirect('/');
}

export function App() {
  return (
      <RouterProvider router={router}/>
  );
}
